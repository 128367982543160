import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import LayoutStyles from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '4rem 2rem 2rem',
    // padding: '0 3rem',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  sectionContent: {
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: '4rem',
    },
  },
  header: {
    paddingBottom: theme.spacing(1),
    fontWeight: '300',
    color: '#7d7d7dde',
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  subtitle: {
    fontWeight: '500',
    paddingBottom: theme.spacing(1),
  },
  btn: {
    margin: theme.spacing(4, 0),
    alignItems: 'center',
  },
  colorLink: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      textDecoration: 'none',
    },
  },
}));

type Props = {
  header: String,
  title: String,
  subtitle: String,
  image: String,
  imageOnLeft: Boolean,
  buttonLabel: String,
  onButtonClick: Function,
  links: Array,
};

function SectionWho(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { header, title, subtitle, imageOnLeft, image, buttonLabel, onButtonClick, links } = props;

  return (
    <Grid
      container
      className={classes.layout}
      style={{ flexDirection: imageOnLeft ? 'row' : 'row-reverse' }}
    >
      <Grid item md={6} xs={12}>
        <img src={image} className={classes.image} alt={title} />
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
        align="left"
        className={classes.sectionContent}
        style={imageOnLeft ? { paddingLeft: 24 } : { paddingRight: 24 }}
      >
        <Typography variant="h4" component="p" className={classes.header} gutterBottom>
          {t(header)}
        </Typography>
        <Typography variant="h2" className={classes.title} gutterBottom>
          {t(title)}
        </Typography>
        <Typography variant="h4" component="h3" className={classes.subtitle} gutterBottom>
          {t(subtitle)}
        </Typography>

        {onButtonClick && (
          <Button
            onClick={onButtonClick}
            color="primary"
            variant="contained"
            className={classes.btn}
          >
            {t(buttonLabel)}
          </Button>
        )}
        {links && (
          <Grid className={classes.btn}>
            {links.map(link => (
              <Grid container key={link.url}>
                <ArrowRightAltIcon fontSize="small" className={classes.colorLink} />
                <Link to={link.url} className={classes.link}>
                  <Typography variant="body1" className={classes.colorLink}>
                    {link.label}
                  </Typography>
                </Link>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
export default SectionWho;
