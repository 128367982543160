import React from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import Footer from '../../Components/Layout/Footer';
import { Images, muiTheme } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import SectionHero from '../../Components/PagesLeagues/SectionHero';
import SectionMatrice from '../../Components/PagesLeagues/SectionMatrice';
import SectionBackgroundImageCard from '../../Components/PagesLeagues/SectionBackgroundImageCard';
import SectionUser from '../../Components/PagesLeagues/SectionUser';
import FooterWello from './Footer';
import SectionOrgaSlider from '../../Components/PagesLeagues/SectionOrgaSlider';
import SectionInfo from '../../Components/PagesLeagues/SectionInfo';
import { getCdnUrl } from '../../Services/ImageResizer';
import {
  GroupeIcon,
  EchangeIcon,
  PlanningIcon,
  ImageIcon,
  InfoIcon,
  GroupeEchangeIcon,
  PeopleEchangeIcon,
  ConstanteIcon,
  EtiquetteIcon,
  LoupeIcon,
  ClickIcon,
  GraphiqueIcon,
  EmpreinteIcon,
  CadenasIcon,
  ConformeIcon,
  WebIcon,
  ActivityIcon,
  TeamPlanningIcon,
  CheckIcon,
} from '../../Themes/Icon';

type Props = {
  classes: Object,
};

const styles = theme => ({
  mainLayout: {
    backgroundColor: '#ffffff',
    padding: 0,
    margin: 0,
  },
  layoutWithGreyBackground: {
    ...LayoutStyles.fullWidthLayout,
    backgroundColor: theme.palette.common.greyBackground,
    margin: theme.spacing(3),
  },
  backgroundGrey: {
    backgroundColor: theme.palette.common.greyBackground,
    padding: theme.spacing(8, 0),
  },
  // heroLayout: {
  //   [theme.breakpoints.only('xs')]: {
  //     backgroundImage: `linear-gradient(rgb(0 0 0 / 40%), rgb(0 0 0 / 20%)),  url(${Images.wello.jumbo})`,
  //   },
  // },
  headingContainer: {
    margin: 'auto',
    padding: theme.spacing(6, 0),
  },
  media: {
    height: 0,
    paddingTop: '50%',
  },
  partenaireMedia: {
    height: 0,
    padding: '15%',
  },
  divider: {
    margin: theme.spacing(15, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(10, 0),
    },
  },
  paperOrga: {
    padding: theme.spacing(1),
    backgroundColor: 'transparent',
  },
});

function Homepage({ classes }: Props) {
  const history = useHistory();

  return (
    <Grid className={classes.mainLayout}>
      {/* <Helmet> use default meta from i18n:
        - ${Routes.currentNamespace()}.SEO.HOMEPAGE.TITLE
        - ${Routes.currentNamespace()}.SEO.HOMEPAGE.DESCRIPTION
      */}

      {/* HERO CTA SECTION */}
      <SectionHero
        backgroundImage={`url(${getCdnUrl(`https://www.wello.fr/${Images.wello.jumbo}`, 1200)})`}
        title1="Organisez la vie à domicile de vos proches"
        title1Color1="#fff"
        title1Color2="#fff"
        body="Wello est la première plateforme qui permet d’organiser la vie à domicile des personnes en perte d’autonomie"
        bodyColor="#fff"
        subTitle="Handicap - Personnes âgées - Affection de longue durée "
        subTitleColor="#fff"
        imageMD={7}
        fullWidthLayout
        paddingTop="10rem"
        layoutHeight="84vh"
      />

      <Divider style={{ margin: '3rem', background: 'none' }} />

      {/* MARGUERITE SECTION */}
      <Grid item className={classes.headingContainer}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center" gutterBottom>
            Échangez entre vous simplement
          </Typography>
          <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
            Wello est la première plateforme permettant d’organiser et de coordonner la vie à
            domicile des personnes en perte d’autonomie
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ margin: '1rem', background: 'none' }} />
      <Grid container>
        <Grid item md={8} xs={12} style={{ margin: 'auto' }}>
          <Card style={{ backgroundColor: 'transparent' }} elevation={0}>
            <CardMedia
              className={classes.media}
              image={getCdnUrl(`https://www.wello.fr/${Images.wello.why}`, 1200)}
            />
          </Card>
        </Grid>
      </Grid>

      <Divider style={{ margin: '3rem', background: 'none' }} />
      <Divider
        style={{ padding: '0.5rem', backgroundColor: muiTheme.palette.common.greyBackground }}
      />

      {/* PRIMARY SECTION */}
      <SectionUser
        header="Aidants, famille, amis …"
        title="Vous êtes aidant"
        subtitle="Faites vos demandes directement en ligne. Les professionnels et tous les membres vous informent de leur passage par un simple message."
        image={getCdnUrl(`https://www.wello.fr/${Images.wello.illu_aidant}`, 800)}
        buttonLabel="EN SAVOIR PLUS"
        onButtonClick={() => history.push('/solution-numerique-aidants')}
      />

      <SectionMatrice
        rowItemSM={3}
        textAlignLeft
        features={[
          {
            id: 1,
            icon: <GroupeIcon style={{ fontSize: '50px' }} />,
            title: 'Invitez des membres',
            subTitle:
              'Choisissez le réseau d’intervenants de votre proche : famille, amis, voisins, service à la personne, infirmiers, etc.',
          },
          {
            id: 2,
            icon: <EchangeIcon style={{ fontSize: '50px' }} />,
            title: 'Echangez simplement',
            subTitle:
              'Un espace commun d’échanges et de partage avec l’ensemble des intervenants professionnels et informels, pour une meilleure coordination autour de son proche.',
          },
          {
            id: 3,
            icon: <PlanningIcon style={{ fontSize: '50px' }} />,
            title: 'Organisez-vous',
            subTitle:
              'Un planning partagé des activités de son proche : consulter les activités de la journée ou semaine, savoir qui fait quoi et quand, ajouter des tâches, modifier les intervenants.',
          },
          {
            id: 4,
            icon: <ImageIcon style={{ fontSize: '50px' }} />,
            title: 'Partagez avec votre proche',
            subTitle:
              'Un accès dédié pour le proche aidé, avec des fonctionnalités simplifiées pour qu’il accède aux photos envoyées par son entourage, à une vision simplifiée du planning, etc.',
          },
        ]}
      />

      <Grid item xs={6} style={{ margin: 'auto' }}>
        <Divider variant="middle" className={classes.divider} />
      </Grid>

      {/* ESTABLISHMENT SECTION */}
      <SectionUser
        header="Établissements et services sociaux ou médico-sociaux"
        title="Vous êtes une structure professionnelle"
        subtitle="Proposez vos services en ligne et offrez à vos bénéficaires une nouvelle facon d’échanger avec eux."
        image={getCdnUrl(`https://www.wello.fr/${Images.wello.illu_establishment}`, 800)}
        links={[
          { label: 'Médico-social', url: '/solution-numerique-medico-social' },
          { label: 'Aide aux Aidants', url: '/solution-numerique-aide-aux-aidants' },
          { label: 'Habitat inclusif', url: '/solution-numerique-habitat-inclusif' },
          { label: 'EHPAD de demain', url: '/solution-numerique-ehpad-de-demain' },
        ]}
      />

      <SectionMatrice
        rowItemSM={3}
        textAlignLeft
        features={[
          {
            id: 9,
            icon: <WebIcon style={{ fontSize: '50px' }} />,
            title: 'Mise en ligne de votre structure',
            subTitle:
              'Référencement internet de votre structure et un fil d’actualités pour informer et interagir avec votre public.',
          },
          {
            id: 10,
            icon: <ActivityIcon style={{ fontSize: '50px' }} />,
            title: 'Services et activités',
            subTitle:
              'Les bénéficiaires peuvent faire directement des demandes en ligne et s’inscrire aux activités.',
          },
          {
            id: 11,
            icon: <TeamPlanningIcon style={{ fontSize: '50px' }} />,
            title: 'Planning des équipes',
            subTitle:
              'Organisez le planning des interventions de vos équipes et de vos bénéficiaires.',
          },
          {
            id: 12,
            icon: <CheckIcon style={{ fontSize: '50px' }} />,
            title: 'Suivi des interventions',
            subTitle:
              'En un simple clic, le professionnel peut valider son passage et saisir un message sur le carnet de liaison.',
          },
          {
            id: 5,
            icon: <InfoIcon style={{ fontSize: '50px' }} />,
            title: 'Vue 360° du bénéficiaire',
            subTitle:
              'Une vision 360° pour connaître la situation du patient, les informations pratiques (code de la porte, etc.) et le nom des intervenants ainsi que leurs rôles.',
          },
          {
            id: 6,
            icon: <GroupeEchangeIcon style={{ fontSize: '50px' }} />,
            title: 'Transmission interne',
            subTitle:
              'Un fil de discussion dédié aux échanges au sein de chaque équipe professionnelle, il n’inclut pas les aidants ou bénéficiaires également membres de la structure.',
          },
          {
            id: 7,
            icon: <PeopleEchangeIcon style={{ fontSize: '50px' }} />,
            title: 'Carnet quotidien & santé',
            subTitle:
              'Un espace commun d’échange et de partage avec l’ensemble des intervenants professionnels et informels, pour une meilleure coordination autour d’une personne à domicile.',
          },
          {
            id: 8,
            icon: <ConstanteIcon style={{ fontSize: '50px' }} />,
            title: 'Suivi des constantes santé',
            subTitle:
              'Un espace de partage et d’échanges à propos des sujets de santé autour d’un patient. L’accès à ces messages et au suivi des constantes est strictement réservé aux membres habilités.',
          },
        ]}
      />

      <Grid item xs={6} style={{ margin: 'auto' }}>
        <Divider variant="middle" className={classes.divider} />
      </Grid>

      {/* PARTNER SECTION */}
      <SectionUser
        header="Partenaires, Mutuelles, Fédérations, Département…"
        title="Vous êtes partenaire"
        subtitle="Pilotez sur votre territoire vos actions pour favoriser l’autonomie. Aide aux aidants, prévention, mobilité, services à domicile, relayage, empowercare…"
        image={getCdnUrl(`https://www.wello.fr/${Images.wello.illu_partner}`, 800)}
        imageOnLeft
        links={[
          { label: 'Plateforme de Services', url: '/solution-numerique-partenaires' },
          { label: 'Plateforme de Care Management', url: '/solution-numerique-care-manager' },
        ]}
      />

      <SectionMatrice
        rowItemSM={3}
        textAlignLeft
        features={[
          {
            id: 13,
            icon: <EtiquetteIcon style={{ fontSize: '50px' }} />,
            title: 'Marque blanche',
            subTitle:
              'Un nom de domaine dédié, une  page d’atterrissage et votre plateforme entièrement personnalisée selon votre thématique et le public cible.',
          },
          {
            id: 14,
            icon: <LoupeIcon style={{ fontSize: '50px' }} />,
            title: 'Recherche et indexation',
            subTitle:
              'Référencement des structures, activités, articles, formations, communautés… Recherche multi critères, les résultats sont géolocalisés.',
          },
          {
            id: 15,
            icon: <ClickIcon style={{ fontSize: '50px' }} />,
            title: 'Inscription en ligne',
            subTitle:
              'Les bénéficiaires peuvent consulter, s’abonner et s’inscrire en ligne. Ils sont notifiés et informés des dernières nouveautés.',
          },
          {
            id: 16,
            icon: <GraphiqueIcon style={{ fontSize: '50px' }} />,
            title: 'Pilotage',
            subTitle:
              'Accès à des outils de pilotage « intelligents » pour détecter des changements d’habitudes, analyser les flux (fréquence et type des échanges…) et alerter si besoin.',
          },
        ]}
      />

      <Divider style={{ margin: '2rem', background: 'none' }} />

      <SectionInfo
        title="Planifiez une démo !"
        subTitle="Nous pouvons vous proposer un accès gratuit en moins de 10’"
        buttonTitle="Consulter notre agenda"
        buttonUrl="https://calendly.com/agenda-wello/demo"
        targetBlank
        backgroundColor={muiTheme.wello.primary.dark}
      />

      <Divider style={{ margin: '4rem', background: 'none' }} />

      {/* PROJECT */}
      <Grid className={classes.backgroundGrey}>
        <Grid className={classes.headingContainer}>
          <Typography variant="h2" align="center" gutterBottom>
            Nos domaines d’application
          </Typography>
          <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
            Des projets innovants pour construire le monde d’après !
          </Typography>
        </Grid>

        <SectionBackgroundImageCard
          cards={[
            {
              id: 5,
              title: 'Habitat inclusif',
              subtitle: 'Vivre ensemble dans un espace de vie partagé et inséré dans la vie locale',
              img: Images.wello.habitat,
              whiteTitle: true,
            },
            {
              id: 6,
              title: 'EHPAD à domicile',
              subtitle:
                'Des professionnels de santé et de l’aide à domicile se coordonnent chez le bénéficiaire',
              img: Images.wello.ehpad,
            },
            {
              id: 7,
              title: 'Retour à domicile',
              subtitle: 'Organiser les retours à domicile et fluidifier le lien hôpital - ville',
              img: Images.wello.retour_domicile,
              whiteTitle: true,
            },
            {
              id: 8,
              title: 'Care management',
              subtitle: 'Un professionnel organise les prestations de service pour votre proche',
              img: Images.wello.care,
              whiteTitle: true,
            },
            {
              id: 9,
              title: 'Relayage',
              subtitle:
                'Un professionnel vient chez vous prendre le relais pendant plusieurs jours',
              img: Images.wello.relayage,
              whiteTitle: true,
            },
            {
              id: 10,
              title: 'Pair aidance',
              subtitle: 'Entraide entre personnes souffrant ou ayant souffert d’une même maladie',
              img: Images.wello.pair_aidance,
              darkGradient: true,
              whiteTitle: true,
            },
          ]}
        />
      </Grid>

      <Divider style={{ margin: '0.5rem', background: 'none' }} />

      {/* JOIN US SECTION */}
      <SectionOrgaSlider
        title="Vous aussi rejoignez Wello !"
        subtitle="Des centaines de professionnels ont fait le pari de la coordination. Ils proposent déjà leurs services en ligne !"
        btnLabel="DECOUVRIR TOUS LES PROFESSIONNELS"
        background
      />
      <Divider style={{ margin: '0.5rem', background: 'none' }} />

      {/* WHO WE ARE SECTION */}
      <Grid className={classes.backgroundGrey}>
        <Grid className={classes.headingContainer} style={{ margin: '0rem 10rem' }}>
          <Typography variant="h2" align="center" gutterBottom>
            Qui sommes nous ?
          </Typography>
          <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
            Wello est une entreprise du numérique agréée ESUS (Entreprise Solidaire d’Utilité
            Sociale) et JEI (Jeune Entreprise Innovante) spécialisée dans la recherche et le
            développement de solutions numériques pour favoriser la vie à domicile des personnes en
            perte d’autonomie et améliorer le quotidien des aidants.
          </Typography>
        </Grid>
        <SectionBackgroundImageCard
          cards={[
            {
              id: 1,
              title: 'Programme de recherche',
              subtitle:
                'Comprendre les enjeux et les mécanismes pour une meilleure coopération des acteurs',
              img: getCdnUrl(`https://www.wello.fr/${Images.wello.programme_recherche}`, 600),
              whiteTitle: true,
            },
            {
              id: 2,
              img: getCdnUrl(`https://www.wello.fr/${Images.wello.recherche_logo}`, 600),
            },
            {
              id: 3,
              title: 'Data science',
              subtitle:
                'Explorez et analysez vos données brutes. Pilotez et valorisez l’impact sur votre territoire',
              img: Images.wello.data_science,
              whiteTitle: true,
            },
            {
              id: 4,
              title: 'Mesure d’impact',
              subtitle:
                'Nous mesurons l’impact de nos solutions pour le maintien à domicile et l’aide aux aidants',
              img: Images.wello.impact,
              whiteTitle: true,
            },
          ]}
        />
      </Grid>

      <Divider style={{ margin: '2rem', background: 'none' }} />

      {/* DATA SECTION */}
      <Grid className={classes.layoutWithGreyBackground}>
        <Grid className={classes.headingContainer}>
          <Typography variant="h2" align="center" gutterBottom>
            Et vos données ?
          </Typography>
          <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
            Le respect de la confidentialité et la protection de vos données sont essentielles pour
            nous.
          </Typography>

          <SectionMatrice
            rowItemSM={3}
            textAlignLeft
            padding
            features={[
              {
                id: 17,
                icon: <EmpreinteIcon style={{ fontSize: '50px' }} />,
                title: 'Confidentialité',
                subTitle:
                  'La gestion des droits d’accès de chacun des membres dans l’application nous permet de garantir un niveau de confidentialité essentiel.',
              },
              {
                id: 18,
                icon: <ConstanteIcon style={{ fontSize: '50px' }} />,
                title: 'Données de Santé',
                subTitle:
                  'Les données stockées sur la plateforme sont hébergées sur des serveurs certifiés HDS. Les serveurs sont localisés en France.',
              },
              {
                id: 19,
                icon: <CadenasIcon style={{ fontSize: '50px' }} />,
                title: 'Sécurité',
                subTitle:
                  'Wello protège vos données grâce à des contrôles tels que les autorisations et le chiffrement en transit et au repos.',
              },
              {
                id: 20,
                icon: <ConformeIcon style={{ fontSize: '50px' }} />,
                title: 'Conformité',
                subTitle:
                  'Wello s’engage à fournir une plateforme conforme aux réglementations françaises et européennes.',
              },
            ]}
          />
        </Grid>
      </Grid>

      <Divider style={{ margin: '2rem', background: 'none' }} />

      {/* VIVALAB SECTION */}
      <Grid container>
        <Grid item className={classes.headingContainer}>
          <Typography variant="h2" align="center" gutterBottom>
            Wello membre du pôle VIVA Lab
          </Typography>
          <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
            Ce pôle repère et accompagne des solutions servicielles, technologiques ou
            organisationnelles à fort potentiel et ayant réussi leur preuve de concept.
          </Typography>
        </Grid>

        <Grid item md={8} xs={12} style={{ margin: 'auto' }}>
          <Card style={{ backgroundColor: 'transparent' }} elevation={0}>
            <CardMedia className={classes.partenaireMedia} image={Images.wello.vivalab} />
          </Card>
        </Grid>
      </Grid>

      <Divider style={{ margin: '3rem', background: 'none' }} />

      {/* FOOTER SECTION */}
      <FooterWello />
      <Footer />
    </Grid>
  );
}

export default withStyles(styles)(Homepage);
