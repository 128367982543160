import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import SectionWho from '../../Components/PagesLeagues/SectionWho';
import SectionPartner from '../../Components/PagesLeagues/SectionPartner';
import Footer from '../../Components/Layout/Footer';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import { Images, muiTheme } from '../../Themes';
import { getCdnUrl } from '../../Services/ImageResizer';
import SectionTestimonial from '../../Components/PagesLeagues/SectionTestimonial';
import SectionHeroCenter from '../../Components/PagesLeagues/SectionHeroCenter';
import SectionArticles from '../../Components/PagesLeagues/SectionArticles';
import { LEAGUE_SLUG } from '../../Services/AlgoliaHelper';
// import SectionInfoCentered from '../../Components/PagesLeagues/SectionInfoCentered';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: '#ffffff',
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '6rem 2rem',
    [theme.breakpoints.only('xs')]: {
      padding: '1rem',
    },
  },
  img: {
    width: '100%',
    margin: theme.spacing(8, 0),
  },
  btn: {
    borderColor: theme.reprise.primary.main,
    color: theme.reprise.primary.main,
    backgroundColor: 'white',
  },
}));

function Homepage() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid className={classes.mainLayout}>
      {/* HERO SECTION */}
      <SectionHeroCenter
        title="Reprise"
        title2="Donnez carte blanche à vos envies !"
        backgroundImage={`url(${Images.reprise.jumbo})`}
        titleColor={muiTheme.reprise.secondary.main}
        title2Color={muiTheme.reprise.primary.main}
        subTitle="Réseau d’Entraide de Proximité à la Réalisation à l’Innovation et au Soutien des Envies"
        subTitleVariant="body1"
        backgroundColor="#f5f5f5"
        justifyContent="flex-start"
      >
        <Button
          variant="outlined"
          onClick={() => history.push('/recherche/structures')}
          className={classes.btn}
        >
          Découvrir les structures partenaires
        </Button>
      </SectionHeroCenter>

      <Divider style={{ margin: '3rem', background: 'none' }} />

      <Grid item className={classes.layout}>
        <Typography variant="h3" component="h2" align="center" gutterBottom>
          Avec Reprise, vos désirs prennent vie
        </Typography>
        <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
          Le projet propose, d’une part, de permettre à la personne âgée en perte d’autonomie de
          faire émerger des désirs et de retrouver un autre horizon que la réalisation des actes du
          quotidien en vue du maintien à domicile (qui sont pris en charge dans le cadre de l’APA),
          et d’autre part, de soulager l’aidant dans la prise en charge de son proche.
        </Typography>
        <img src={Images.reprise.wishes} className={classes.img} alt="Désirs avec Reprise" />
        <Grid align="right">
          <Button
            variant="outlined"
            size="small"
            onClick={() => history.push('/recherche/activites')}
          >
            VOIR TOUTES LES ACTIVITÉS
          </Button>
        </Grid>
      </Grid>

      <Divider style={{ margin: '2rem', background: 'none' }} />

      <SectionTestimonial
        title="Ils en parlent"
        subTitle="Des utilisateurs déjà conquis par la méthode reprise, pourquoi pas vous !"
        testimonials={[
          {
            avatar: Images.reprise.avatar1,
            title: 'Élise',
            location: 'Lille',
            content:
              '« Avoir des projets aide a donner du sens à la vie et a un impact positif sur la santé »',
          },

          {
            avatar: Images.reprise.avatar3,
            title: 'Marie',
            location: 'Lille',
            content:
              "« Avec la méthode REPRISE je revis. Je sors de nouveau pour rencontrer d'autres personnes, je participe à des activités, je visite moi-même d'autres personnes »",
          },
          {
            avatar: Images.reprise.avatar2,
            title: 'Marcel',
            location: 'Lille',
            content: '« Je vis, je choisis, je me fais aider pour réaliser mes envies »',
          },
        ]}
      />

      {/* <Grid item className={classes.layout}>
        <Typography variant="h3" component="h2" align="center" gutterBottom>
          Ils ont réalisé leurs envies
        </Typography>
        <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
          Ils ont participé au programme Reprise en exprimant leurs souhaits… Vous aussi, réalisez
          les votre !
        </Typography>
        <img
          src={getCdnUrl(`https://www.wello.fr/${Images.reprise.video}`, 1200)}
          className={classes.img}
          alt="Souhaits du programme Reprise"
        />
      </Grid> */}

      {/* <Grid xs={6} style={{ margin: 'auto' }}>
        <Divider variant="middle" className={classes.divider} />
      </Grid> */}

      <Divider style={{ margin: '2rem', background: 'none' }} />

      <SectionWho
        title="Cultivez également l’envie au quotidien"
        variantTitle="h3"
        bullet={[
          {
            bullet: ' ',
            title: 'Vous aussi, participez à cette expérimentation !',
            content:
              '<p>Vous êtes une personne de + de 60 ans, confronté(ée) à une perte d’autonomie qui nécessite que vous ayez recours à l’aide de tiers, professionnels ou Aidants, dans bien des actes de votre vie quotidienne.</p><p>Vous avez pourtant envie d’accomplir des projets, de réaliser des envies et pour cela, réussir à dépasser vos difficultés. Alors, nous pouvons vous aider !</p><p>Nous vous proposons de participer à l’élaboration d’une méthode que nous expérimentons avec une équipe de professionnels, de chercheurs et de citoyens engagés dans l’aide.</p>',
          },
          {
            bullet: ' ',
            title: 'Développez votre réseau de proximité !',
            content:
              '<p>En mobilisant les ressources de votre entourage, nous pouvons vous aider à réaliser vos envies !</p><p>Amis, famille, voisin mais aussi associations et professionnels peuvent vous accompagner pour organiser vos envies au quotidien.</p>',
          },
        ]}
        image={getCdnUrl(`https://www.wello.fr/${Images.reprise.sociogramme}`, 800)}
      />

      <SectionWho
        title="Une application simple"
        variantTitle="h3"
        bullet={[
          {
            bullet: ' ',
            content:
              '<p>L’application numérique vous permet à vous et votre réseau de mieux communiquer autour de votre projet. Votre entourage a accès à votre planning personnel et peut donc s’organiser et vous proposer des activités : vous accompagner en ville, partager un repas…</p><p>Ces actions sont centrées autour de vous et de vos envies. L’application favorise également les discussions au sein de votre réseau et permet de s’échanger des informations pour une meilleure organisation de votre quotidien et la réalisation de vos projets au jour le jour.</p>',
          },
        ]}
        // image={getCdnUrl(`https://www.wello.fr/${Images.reprise.planning}`, 800)}
        image={Images.reprise.planning}
        imageOnRight
      />

      <SectionArticles
        title="Les dernières actualités"
        subTitle="Retrouvez des informations concernant les pathologies, la législation, le rôle d'aidant, les démarches administratives, les nouvelles aides, etc."
        buttonLabel="Découvrir tous les articles"
        onButtonClick={() => history.push('/recherche/articles')}
        leagueSlug={LEAGUE_SLUG.Reprise}
      />

      {/* <SectionInfoCentered
        title="Contactez-nous"
        subtitle="Participez, vous aussi, au projet Reprise pour égayer votre quotidien !"
        buttonTitle="Nous contacter"
        buttonUrl="mailto:hello@wello.fr"
        btnColor={muiTheme.reprise.primary.main}
        backgroundColor={muiTheme.palette.common.greyBackground}
      /> */}

      <Divider style={{ margin: '3rem', background: 'none' }} />

      <SectionPartner
        title="Qui sommes-nous ?"
        subTitle="Une initiative Empowercare portée par le département du Nord, en partenariat avec l’IRTS et Wello"
        rowItemSM={6}
        partners={[
          {
            image: Images.reprise.partner1,
            titlePartner: 'Empowercare',
            contentPartner:
              "Le programme Interreg 2 mers est cofinancé par le Fonds Européen de développement régional (FEDER). Au plan local, Empowercare mobilise des partenaires institutionnels, associatifs et des aidants de personnes âgées, s'appuyant sur l'expertise d'usage.",
            linkUrl: 'https://www.interreg2seas.eu/en/EMPOWERCARE',
          },
          {
            image: Images.reprise.partner2,
            titlePartner: 'Autonomie et prévention',
            contentPartner:
              'Au sein du département du Nord, le pôle Autonomie de la Direction Territoriale de Prévention et d’Action Sociale des Flandres regroupe les compétences destinées au public des personnes âgées et des personnes en situation de handicap et porte le projet d’Empowercare.',
            linkUrl: 'https://lenord.fr/',
          },
          {
            image: Images.wello.partner,
            titlePartner: 'WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.TITLE2',
            contentPartner: 'WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.CONTENT',
            linkUrl: 'https://www.wello.fr/',
          },
          {
            image: Images.reprise.partner3,
            titlePartner: 'Recherche sociale',
            contentPartner:
              'L’IRTS Hauts-de-France, en collaboration avec l’Université de Lille, conduit une démarche de recherche action dont les résultats viennent alimenter la réalisation de l’application numérique.',
            linkUrl: 'https://irtshdf.fr/',
          },
        ]}
      />

      <Divider style={{ margin: '2rem', background: 'none' }} />
      {/* FOOTER SECTION */}
      <Footer />
    </Grid>
  );
}

export default Homepage;
