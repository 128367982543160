import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';

import SectionInfo from '../../Components/PagesLeagues/SectionInfo';
import ChbSectionHeader from '../CHB/ChbSectionHeader';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import SectionHero from '../../Components/PagesLeagues/SectionHero';
import {
  PreventionIcon,
  SoutienPsyIcon,
  CafeIcon,
  TransportIcon,
  FormationIcon,
} from '../../Themes/Icon';
import { Images, Metrics, muiTheme } from '../../Themes';
import SectionWho from '../../Components/PagesLeagues/SectionWho';
import Footer from '../../Components/Layout/Footer';
import SectionPartner from '../../Components/PagesLeagues/SectionPartner';
import SectionContent from '../../Components/PagesLeagues/SectionContent';
import SectionArticles from '../../Components/PagesLeagues/SectionArticles';
import { LEAGUE_SLUG } from '../../Services/AlgoliaHelper';
import SectionActivity from '../../Components/PagesLeagues/SectionActivity';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: '#ffffff',
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '4rem 2rem',
    [theme.breakpoints.only('xs')]: {
      margin: '4rem 1rem',
    },
  },
  searchBtn: {
    backgroundColor: theme.global.primary.main,
    color: theme.global.secondary.dark,
    padding: theme.spacing(1, 6),
    marginBottom: theme.spacing(1),
  },
  tabsSubtitle: {
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    ...LayoutStyles.fullWidthLayout,
    margin: '4rem 2rem',
    [theme.breakpoints.only('xs')]: {
      margin: '4rem 1rem',
      flexDirection: 'column',
    },
    '&>*': {
      flex: '1 1 0',
      padding: theme.spacing(2),
      textAlign: 'left',
      borderRadius: 4,
    },
  },
  iconTab: {
    fontSize: 56,
    color: theme.global.secondary.dark,
  },
  textTab: {
    margin: theme.spacing(2, 0, 1),
    lineHeight: 1.1,
  },
  divider: {
    margin: '8rem 0',
    padding: '0.5rem',
    backgroundColor: '#E0E0E0',
    [theme.breakpoints.only('xs')]: {
      margin: '4rem 0',
    },
  },
}));

function Homepage() {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();

  const sectionRefs = React.useRef([]);
  sectionRefs.current.home = React.createRef();
  sectionRefs.current.section1 = React.createRef();
  sectionRefs.current.section2 = React.createRef();
  sectionRefs.current.section3 = React.createRef();
  sectionRefs.current.section4 = React.createRef();
  sectionRefs.current.section5 = React.createRef();

  const onTabValueChange = (event, newValue) => {
    event.preventDefault();
    if (sectionRefs.current[newValue] && sectionRefs.current[newValue].current) {
      window.scrollTo({
        top: sectionRefs.current[newValue].current.offsetTop - Metrics.navBarHeight - 100,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Grid className={classes.mainLayout} ref={sectionRefs.current.home}>
      {/* HERO SECTION */}
      <SectionHero
        header="Aidants, Alzheimer et maladies apparentées"
        title1="Toutes les solutions pour un accompagnement global de votre proche"
        headerColor={muiTheme.global.secondary.dark}
        title1Color1={muiTheme.global.secondary.dark}
        title1Color2={muiTheme.global.secondary.dark}
        image={Images.global.hero}
        imageWidth={500}
        imageMD={5}
        fullWidthLayout
        paddingTop="3rem"
        backgroundColor="#f6f6f6"
        layoutHeight="90vh"
      >
        <Divider style={{ margin: '3rem', background: 'none' }} />
        <Button
          variant="contained"
          className={classes.searchBtn}
          onClick={() =>
            history.push(
              '/recherche/structures?city=Rennes&address=Rennes,%20Ille-et-Vilaine,%20France&lat=48.111339&long=-1.68002&bbox=-1.752606;48.076756;-1.624404;48.154894',
            )
          }
        >
          Rechercher à Rennes *
        </Button>
        <Typography
          variant="caption"
          paragraph
          style={{
            color: muiTheme.global.secondary.dark,
          }}
        >
          * Prochainement disponible sur toute la France
        </Typography>
        <Divider style={{ margin: '3rem', background: 'none' }} />
        <Grid item md={9} xs={12}>
          <Typography
            style={{
              color: muiTheme.global.secondary.dark,
            }}
          >
            GLOBAL est une démarche inclusive initiée par l’association Bistrot Mémoire Rennais pour
            faciliter la vie des personnes vivant avec une démence, leurs proches-aidants et les
            professionnels qui les accompagnent au quotidien.
          </Typography>
        </Grid>
        <Divider style={{ margin: '1rem', background: 'none' }} />
      </SectionHero>

      <Hidden only={['xs']}>
        <Divider style={{ margin: '3rem', background: 'none' }} />
      </Hidden>

      <Grid container className={classes.tabsSubtitle}>
        <CardActionArea onClick={event => onTabValueChange(event, 'section1')}>
          <PreventionIcon className={classes.iconTab} />
          <Typography
            variant="h5"
            component="div"
            className={classes.textTab}
            dangerouslySetInnerHTML={{
              __html: t('Se faire accompagner'),
            }}
          />
          <Typography>
            Trouvez en un clic des professionnels près de chez vous, pour vous accompagner dans les
            gestes du quotidien
          </Typography>
        </CardActionArea>
        <CardActionArea onClick={event => onTabValueChange(event, 'section2')}>
          <SoutienPsyIcon className={classes.iconTab} />
          <Typography
            variant="h5"
            component="div"
            className={classes.textTab}
            dangerouslySetInnerHTML={{
              __html: t('Se faire soigner'),
            }}
          />
          <Typography>
            Echangez avec les professionnels de santé via un espace sécurisé pour suivre vos
            rendez-vous
          </Typography>
        </CardActionArea>
        <CardActionArea onClick={event => onTabValueChange(event, 'section3')}>
          <FormationIcon className={classes.iconTab} />
          <Typography
            variant="h5"
            component="div"
            className={classes.textTab}
            dangerouslySetInnerHTML={{
              __html: t('Se former'),
            }}
          />
          <Typography>
            Gagnez en connaissances sur les pathologies et en compétences pour accompagner votre
            proche
          </Typography>
        </CardActionArea>
        <CardActionArea onClick={event => onTabValueChange(event, 'section4')}>
          <CafeIcon className={classes.iconTab} />
          <Typography
            variant="h5"
            component="div"
            className={classes.textTab}
            dangerouslySetInnerHTML={{
              __html: t('Se divertir'),
            }}
          />
          <Typography>
            Retrouvez toutes les activités en lien avec vos centres d’intérêt près de chez vous
          </Typography>
        </CardActionArea>
        <CardActionArea onClick={event => onTabValueChange(event, 'section5')}>
          <TransportIcon className={classes.iconTab} />
          <Typography
            variant="h5"
            component="div"
            className={classes.textTab}
            dangerouslySetInnerHTML={{
              __html: t('Se déplacer'),
            }}
          />
          <Typography>
            Programmez et sécurisez vos déplacements grâce à un réseau de volontaires engagés
          </Typography>
        </CardActionArea>
      </Grid>

      <Divider style={{ margin: '3rem', background: 'none' }} />

      <SectionWho
        title="Pourquoi avons-nous créé cette plateforme ?"
        bullet={[
          {
            bullet: ' ',
            title: '',
            content:
              "<p>Pour bâtir une société bienveillante et inclusive en faveur des personnes vivant avec une maladie neuro-évolutive (Alzheimer, Maladie à corps de Lewy, maladie de Charcot…). L’enjeu est de fédérer l’ensemble des acteurs du médico-social et de l’accompagnement autour d’une culture commune du prendre-soin.</p><p>En effet, à défaut d’un traitement curatif, il va falloir aller plus loin encore, dans les approches psycho-sociales dont les effets ne sont plus à démontrer !</p><p>En France, 1,2 million de personnes sont atteintes de la maladie d'Alzheimer. Un nouveau cas est diagnostiqué toutes les 2 min 30*. Face à l’ampleur de ce phénomène, nous devons mobiliser toutes les solutions locales qui permettront à chacun de préserver sa place et son rôle dans la société.</p><p>Les effets de ces maladies touchent à tout l’éco-système du patient et de ses proches-aidants.</p><p>Il est urgent de proposer un accompagnement global où chacun (personne malade, proche-aidant, soignants, acteurs de proximité, bénévoles…) à un rôle à jouer pour faire de cette problématique : une priorité citoyenne !</p>*(Santé publique France, 2019; INSERM, 2007)<p>",
          },
        ]}
        image={Images.global.who}
      />

      <Divider style={{ margin: '3rem', background: 'none' }} />
      <SectionArticles
        title="Les dernières actualités"
        subTitle="Retrouvez des informations concernant les pathologies, les nouvelles aides, les démarches administratives, etc."
        buttonLabel="Découvrir tous les articles"
        onButtonClick={() => history.push('/recherche/articles')}
        leagueSlug={LEAGUE_SLUG.Global}
      />

      <Divider className={classes.divider} />

      <div ref={sectionRefs.current.section1}>
        <ChbSectionHeader
          title="Se faire accompagner"
          subtitle="Il existe plusieurs dispositifs, souvent méconnus, pour vous accompagner au quotidien."
        />
        <SectionContent
          title="À vos côtés"
          paragraph="<p>Accompagner un proche vivant avec une maladie d’Alzheimer est un véritable parcours du combattant ! Les différents stades de la maladie évoluant sur plusieurs années : vous ne pourrez tenir sur la durée qu’à condition de mettre en place plusieurs stratégies : à commencer par savoir bien s’entourer.</p><p>Sur cette plateforme, vous trouverez facilement le bon interlocuteur, au bon moment et au bon endroit.</p>"
          image={Images.global.accompagner}
          imgWidth={400}
          buttonLabel="Découvrir les structures"
          buttonLink={
            '/recherche/structures?city=Rennes&address=Rennes,%20Ille-et-Vilaine,%20France&lat=48.111339&long=-1.68002&bbox=-1.752606;48.076756;-1.624404;48.154894&a_type=Organisations::Association|Organisations::Pfr'
          }
          imageOnLeft
          rowItemTextSM={5}
          h3
          variantButton="contained"
          btnBackgroundColor={muiTheme.global.primary.main}
        />
      </div>

      <Divider className={classes.divider} />

      <div ref={sectionRefs.current.section2}>
        <ChbSectionHeader
          title="Se faire soigner"
          subtitle="La maladie d’Alzheimer ou apparentées viennent brouiller tous les repères, entraînant des retards de diagnostics pour les personnes et leurs proches aidants."
        />
        <SectionContent
          title="Prévenir plutôt que guérir"
          paragraph="<p>Il est important d’être acteur de sa santé, encore faut-il trouver un professionnel disponible et formé aux spécificités de ces pathologies.</p><p>Sur notre plateforme, vous trouverez un réseau d’acteurs (gériatres, psychologues, orthophonistes, infirmiers…) sensibilisés et à l’écoute de vos besoins.</p>"
          image={Images.global.soigner}
          imgWidth={400}
          buttonLabel="Trouver un professionnel"
          buttonLink={
            '/recherche/structures?city=Rennes&address=Rennes,%20Ille-et-Vilaine,%20France&lat=48.111339&long=-1.68002&bbox=-1.752606;48.076756;-1.624404;48.154894&a_type=Organisations::Establishment|Organisations::Sap|Organisations::Healthcare'
          }
          rowItemTextSM={5}
          h3
          variantButton="contained"
          btnBackgroundColor={muiTheme.global.primary.main}
        />
      </div>

      <Divider className={classes.divider} />

      <div ref={sectionRefs.current.section3}>
        <ChbSectionHeader
          title="Se former"
          subtitle="Partager les savoirs être, dire et faire pour créer une culture commune du prendre soin."
        />
        <SectionContent
          title="Partager des connaissances"
          paragraph="<p>Acquérir des connaissances et des compétences sur les spécificités des maladies neuro-évolutives, accompagner sans s'épuiser tout en préservant la relation avec son proche et cela à son rythme.<p></p>Cette plateforme vous propose plusieurs thématiques et formats (présentiel/distanciel) pour que vous puissiez développer une stratégie d'accompagnement sur-mesure. Et cela quelque soient vos contraintes (aidants en activité, jeunes aidants...)</p>"
          image={Images.global.former}
          imgWidth={400}
          imageOnLeft
          buttonLabel="Participer à une formation"
          buttonLink={
            '/recherche/formations?city=Rennes&address=Rennes,%20Ille-et-Vilaine,%20France&lat=48.111339&long=-1.68002&bbox=-1.752606;48.076756;-1.624404;48.154894'
          }
          rowItemTextSM={5}
          h3
          variantButton="contained"
          btnBackgroundColor={muiTheme.global.primary.main}
        />
      </div>

      <Divider className={classes.divider} />

      <div ref={sectionRefs.current.section4}>
        <ChbSectionHeader
          title="Se divertir"
          subtitle="Une des meilleures façon de lutter contre l’évolution de ces pathologies est de préserver le lien social !"
        />
        <SectionContent
          title="Rompre l'isolement"
          paragraph="<p>C’est prouvé : pour lutter contre les effets de la maladie, avoir une vie sociale riche et divertissante est fortement recommandée. Sur la plateforme, vous trouverez toutes les activités, en lien avec vos centres d’intérêts, organisées près de chez vous.</p>"
          image={Images.global.divertir}
          imgWidth={400}
          imageOnLeft
          rowItemTextSM={5}
          h3
          variantButton="contained"
          btnBackgroundColor={muiTheme.global.primary.main}
        />
        <Divider style={{ margin: '3rem', background: 'none' }} />
        <SectionActivity leagueSlug={LEAGUE_SLUG.Global} />
        <Grid className={classes.layout} align="right" variant="contained">
          <Button
            onClick={() =>
              history.push(
                '/recherche/activites?city=Rennes&address=Rennes,%20Ille-et-Vilaine,%20France&lat=48.111339&long=-1.68002&bbox=-1.752606;48.076756;-1.624404;48.154894',
              )
            }
            style={{ backgroundColor: muiTheme.global.primary.main, marginTop: -16 }}
          >
            Découvrir toutes les activités
          </Button>
        </Grid>
      </div>

      <Divider className={classes.divider} />

      <div ref={sectionRefs.current.section5}>
        <ChbSectionHeader
          title="Se déplacer"
          subtitle="Pour se divertir ou se soigner encore faut-il pouvoir se déplacer…"
        />
        <SectionContent
          title="Faciliter les trajets"
          paragraph="<p>Les déplacements sont une des premières sources d’anxiété lorsqu’on vit avec une démence. Afin de vous rassurer et faciliter vos déplacements, vous avez la possibilité de solliciter une aide.</p><p>Un réseau solidaire (bénévoles, volontaires en service civique) se mobilise pour préparer vos déplacements, se rendre à un rendez-vous médical ou vous accompagner aux activités proposées sur la plateforme.</p><p></p>"
          image={Images.global.deplacer}
          imgWidth={400}
          buttonLabel="Faire une demande de déplacement"
          buttonLink={
            '/recherche/transports?city=Rennes&address=Rennes,%20Ille-et-Vilaine,%20France&lat=48.111339&long=-1.68002&bbox=-1.752606;48.076756;-1.624404;48.154894'
          }
          rowItemTextSM={5}
          h3
          variantButton="contained"
          btnBackgroundColor={muiTheme.global.primary.main}
        />
      </div>

      <Divider className={classes.divider} />

      <ChbSectionHeader
        title="En savoir plus sur la démarche GLOBAL"
        subtitle="Vivre avec la maladie : déconstruire les représentations des pathologies neuro-évolutives.. L’association défend globalement l’idée d’une société inclusive, c’est-à-dire qui fait une place à tout le monde, et pas seulement à un individu jeune et valide. Soit une société qui s’adapte aux personnes atteintes de troubles cognitifs."
      />
      {/* <Grid className={classes.layout} align="center">
        <img src={Images.global.demarche} style={{ maxWidth: '100%', borderRadius: 4 }} />
      </Grid> */}
      <SectionInfo
        title="« Rien pour eux sans eux ! »"
        body="Avec la participation active des personnes malades : premiers experts de la maladie, l’association sensibilise et mobilise l’ensemble des acteurs de proximité pour bâtir une société bienveillante et inclusive à l’égard de ce public, encore trop stigmatisé !"
        buttonTitle="Nous contacter"
        backgroundColor={muiTheme.global.primary.main}
        callMeBackInModal
        // organisation={organisationGlobalCTA}
        color={muiTheme.global.secondary.dark}
        BtnBackgroundColor={muiTheme.global.secondary.dark}
      />

      <SectionPartner
        title="Qui sommes-nous ?"
        subTitle="Une inititiative du Bistrot Mémoire Rennais en partenariat avec la société WELLO"
        rowItemSM={6}
        partners={[
          {
            image: Images.global.partner,
            titlePartner: 'Véritable laboratoire d’innovations sociales',
            contentPartner:
              'Depuis 2004 l’association Bistrot Mémoire Rennais milite pour préserver la place et le rôle des personnes vivant avec des difficultés cognitives au sein de la cité. L’association est à l’initiative en France de la démarche « Dementia Friendly » avec comme défi : face à cette problématique, apporter une réponse citoyenne !',
            linkUrl: 'https://www.bistrotmemoirerennais.com/page/1047462-accueil',
          },
          {
            image: Images.wello.partner,
            titlePartner: 'WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.TITLE2',
            contentPartner: 'WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.CONTENT',
            linkUrl: 'https://www.wello.fr/',
          },
        ]}
      />

      <Divider style={{ margin: '2rem', background: 'none' }} />
      {/* FOOTER SECTION */}
      <Footer />
    </Grid>
  );
}

export default Homepage;
