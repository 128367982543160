import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import SectionHeroTitleDown from '../../Components/PagesLeagues/SectionHeroTitleDown';
import SectionContent from '../../Components/PagesLeagues/SectionContent';
import SectionNeed from '../../Components/PagesLeagues/SectionNeed';
import SectionPartner from '../../Components/PagesLeagues/SectionPartner';
import Footer from '../../Components/Layout/Footer';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import { Images, muiTheme } from '../../Themes';
import {
  CafeIcon,
  CarteIcon,
  NumeriqueIcon,
  ServiceIcon,
  TransportIcon,
  BienEtreIcon,
  AdministrativesIcon,
  InformationIcon,
} from '../../Themes/Icon';
import SectionOrgaSlider from '../../Components/PagesLeagues/SectionOrgaSlider';
import ButtonCallMeBackInModal from '../../Components/Shared/ButtonCallMeBackInModal';
import SectionActivity from '../../Components/PagesLeagues/SectionActivity';
import { LEAGUE_SLUG } from '../../Services/AlgoliaHelper';
import ChbSectionHeader from '../CHB/ChbSectionHeader';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: '#ffffff',
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '4rem 2rem',
    flexWrap: 'nowrap',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '1rem',
      flexDirection: 'column',
    },
  },
  ctaContainer: {
    ...LayoutStyles.fullWidthLayout,
    margin: '5rem 3rem 5rem',
    padding: '3rem 3rem 3rem',
    borderRadius: '4px',
    backgroundColor: theme.ne.primary.main,
    '&>*': { color: '#fff' },
  },
  btn: {
    marginTop: theme.spacing(3),
  },
  img: {
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    },
  },
  firstDivider: {
    margin: '5rem',
    background: 'none',
    [theme.breakpoints.down('sm')]: {
      margin: '3rem',
    },
  },
}));

function Homepage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Grid className={classes.mainLayout}>
      {/* HERO SECTION */}
      <SectionHeroTitleDown
        title1="Bien vivre à Nantes"
        subTitle="Des initiatives solidaires pour les habitants 
          du quartier Nantes Erdre"
        backgroundImage={`linear-gradient(to bottom, rgba(255,255,255,0), rgba(11,11,11,0.9)), url(${Images.ne.jumbo})`}
        title1Color1="#fff"
        title1Color2="#fff"
        subTitleColor="#fff"
        variantSubTitle="h3"
        componentSubTitle="h2"
      />

      <Divider className={classes.firstDivider} />

      <SectionContent
        title="Pourquoi cette plateforme ?"
        paragraph="<p>Vous habitez dans le quartier Nantes Erdre et vous souhaitez participer à des activités ?</p><p>Vous avez besoin d’aide pour vos déplacements, pour faire vos démarches administratives, ou vous souhaitez faire des rencontres et partager des moments avec d’autres habitants près de chez vous ?</p><p>Les structures du quartier coopèrent pour mieux vous accompagner et répondre aux besoins que vous rencontrez.</p>"
        image={Images.ne.why}
        rowItemTextSM={5}
      />

      <Hidden only="xs">
        <Divider style={{ margin: '5rem', background: 'none' }} />
      </Hidden>

      <ChbSectionHeader
        title="Être bien accompagné,"
        title2="tisser un lien de confiance et s’épanouir"
      />
      <SectionNeed
        rowItemSM={3}
        needs={[
          {
            icon: <CafeIcon />,
            title: 'Lien social à domicile',
            subTitle:
              'Échanger autour d’un café, faire des jeux, partager un moment convivial chez soi',
          },
          {
            icon: <CarteIcon />,
            title: 'Rencontres collectives',
            subTitle:
              'Partager des loisirs et des sorties : activités ludiques, culturelles, créatives…',
          },
          {
            icon: <NumeriqueIcon />,
            title: 'Aide au numérique',
            subTitle: 'Utilisation des technologies numériques (appareils, applications, outils…)',
          },
          {
            icon: <ServiceIcon />,
            title: 'Petit service',
            subTitle: 'Un coup de main ponctuel : bricolage, aide pour faire les courses…',
          },
          {
            icon: <TransportIcon />,
            title: 'Transports solidaires',
            subTitle:
              'Accompagnement dans les transports en commun, faire une balade, se déplacer              ',
          },
          {
            icon: <BienEtreIcon />,
            title: 'Santé et bien-être',
            subTitle:
              'Des activités de prévention, de détente et de bien-être en solo ou en groupe',
          },
          {
            icon: <AdministrativesIcon />,
            title: 'Aide administrative',
            subTitle:
              'Accompagnement à l’écriture et et à la relecture des dossiers administratifs',
          },
          {
            icon: <InformationIcon />,
            title: 'Point ressources',
            subTitle:
              'Informations et mise en lien avec les acteurs du territoire, accès aux droits',
          },
        ]}
        iconColor={muiTheme.ne.primary.main}
      />

      <Hidden only="xs">
        <Divider style={{ margin: '5rem', background: 'none' }} />
      </Hidden>

      <ChbSectionHeader title=" Ces structures vous accompagnent" />
      <SectionOrgaSlider leagueSlug={LEAGUE_SLUG.NE} />

      <Divider style={{ margin: '5rem', background: 'none' }} />

      <Grid align="center" className={classes.ctaContainer}>
        <Typography variant="h3" component="h2" gutterBottom>
          Vous avez besoin d&apos;aide ?
        </Typography>
        <Typography variant="subtitle1" component="div" gutterBottom>
          …ou vous connaissez une personne qui a besoin d’aide ?
        </Typography>
        <Typography variant="subtitle1" component="div" gutterBottom>
          Laissez-nous vous guider et trouver des solutions près de chez vous !
        </Typography>
        <Grid className={classes.btn}>
          <ButtonCallMeBackInModal
            withContainedButton
            // organisation={organisationNeCTA}
            withPostCode
            withEmail
            withPhone
            buttonLabel={t('CONTACT_US')}
            descriptionPlaceHolder={t('APP.SLA.ASK_CONTACT.TEXT_FIELD.LABEL.NEEDS.PLACEHOLDER')}
            backgroundColor="#fff"
            gtmOrigin="landing"
          />
        </Grid>
      </Grid>

      <Divider style={{ margin: '3rem', background: 'none' }} />

      <SectionActivity
        leagueSlug={LEAGUE_SLUG.NE}
        expertises={[
          'activites-creatives',
          'activites-musicales',
          'activites-sportives',
          'jeux',
          'maintien-en-forme',
          'rencontres-conviviales',
          'sorties-culturelles',
        ]}
        buttonLabel="Voir toutes les activités"
        onButtonClick={() => history.push('/recherche/activites')}
      >
        <ChbSectionHeader
          title="Participer aux activités"
          title2="et partager des moments conviviaux"
          subtitle="Venez rencontrer et échanger avec des habitants de votre quartier lors d’activités. Balades, activités créatives, rencontres artistiques, soutien scolaire, atelier à destination des personnes âgées : profitez de moments d’échange intergénérationnels !"
        />
      </SectionActivity>

      <SectionActivity
        leagueSlug={LEAGUE_SLUG.NE}
        expertises={['detente-bien-etre', 'groupes-de-parole-2', 'ateliers-de-prevention']}
        buttonLabel="Voir toutes les activités"
        onButtonClick={() =>
          history.push(
            '/recherche/activites?a_expertise.slug=detente-bien-etre|groupes-de-parole-2|ateliers-de-prevention',
          )
        }
      >
        <ChbSectionHeader
          title="Prendre soin de soi"
          title2="et participer à des activités de prévention"
          subtitle="Les professionnels de votre quartier vous proposent de participer à des ateliers collectifs de prévention sur le thème de la santé et du bien-être."
        />
      </SectionActivity>

      <Divider style={{ margin: '3rem', background: 'none' }} />

      <Grid container className={classes.layout}>
        <Grid item md={5} xs={12}>
          <Typography variant="h2">Se déplacer</Typography>
          <Typography variant="h3" style={{ fontWeight: 600 }} gutterBottom>
            avec l’aide des professionnels et des bénévoles
          </Typography>
          <Divider style={{ margin: '2rem', background: 'none' }} />
          <Typography paragraph gutterBottom>
            Se faire accompagner pour se rendre à une activité, être conduit pour faire ses courses,
            etc.
          </Typography>
          <Typography paragraph gutterBottom>
            De nombreuses solutions de mobilité sont possibles auprès de nos bénévoles et nos
            professionnels !
          </Typography>
        </Grid>
        <Grid item md={7} sm={12} className={classes.img}>
          <img src={Images.ne.deplacement} style={{ maxWidth: '100%', borderRadius: 4 }} />
        </Grid>
      </Grid>

      <Divider style={{ margin: '5rem', background: 'none' }} />

      <Grid align="center" className={classes.ctaContainer}>
        <Typography variant="h3" component="h2" gutterBottom>
          Vous souhaitez devenir bénévole
        </Typography>
        <Typography variant="subtitle1" component="div" gutterBottom>
          pour aider des personnes âgées dans votre voisinage ?
        </Typography>
        <Grid className={classes.btn}>
          <ButtonCallMeBackInModal
            withContainedButton
            // organisation={organisationNeCTA}
            withPostCode
            withEmail
            withPhone
            buttonLabel="DEVENIR BÉNÉVOLE"
            descriptionPlaceHolder={t('APP.NE.ASK_CONTACT.TEXT_FIELD.LABEL.VOLUNTEER.PLACEHOLDER')}
            descriptionLabel={t('APP.ASK_CONTACT.TEXT_FIELD.LABEL.VOLUNTEER.LABEL')}
            backgroundColor="#fff"
            gtmOrigin="landing"
          />
        </Grid>
      </Grid>

      <Divider style={{ margin: '3rem', background: 'none' }} />

      <SectionPartner
        title="Qui sommes-nous ?"
        subTitle="Une initiative de l’association Manou Partages en partenariat avec Wello"
        rowItemSM={6}
        partners={[
          {
            image: Images.ne.partner,
            titlePartner: 'LA SOLIDARITÉ, LE COLLECTIF',
            contentPartner:
              "L'association Manou Partages, reconnue d’intérêt général, est un facilitateur de lien entre les générations et une passerelle de proximité qui favorise la liaison entre les habitant.e.s et les acteurs institutionnels et associatifs du quartier Nantes Erdre.",
          },
          {
            image: Images.wello.partner,
            titlePartner: 'WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.TITLE2',
            contentPartner: 'WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.CONTENT',
            linkUrl: 'https://www.wello.fr/',
          },
        ]}
      />

      <Divider style={{ margin: '2rem', background: 'none' }} />
      {/* FOOTER SECTION */}
      <Footer />
    </Grid>
  );
}

export default Homepage;
