import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { InstantSearch, connectHits, Configure } from 'react-instantsearch-dom';
import Routes from '../../Routing/Routes';

import Typography from '@material-ui/core/Typography';
import Slider from 'react-slick';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CardOrganisation from '../Organisations/Shared/CardOrganisation';
import fakeOrganisation from '../../PagesLeagues/Wello/fake-organisation';
import LayoutStyles, { isMobile } from '../Layout/Styles/LayoutStyles';
import { searchClient, INDEX_NAMES, FILTERING_ATTRIBUTES } from '../../Services/AlgoliaHelper';

const useStyles = makeStyles(theme => ({
  gradientBackground: {
    background: 'linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(255,255,255,0) 100%)',
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
  },
  layoutWithPadding: {
    ...LayoutStyles.fullWidthLayout,
    padding: theme.spacing(8, 0),
  },
  headingContainer: {
    margin: 'auto',
    padding: theme.spacing(6, 0),
  },
  paperOrga: {
    padding: theme.spacing(1),
    backgroundColor: 'transparent',
  },
}));

type Props = {
  title: String,
  subtitle: String,
  btnLabel: String,
  leagueSlug: String,
  background: Boolean,
};

const SectionOrgaSlider = ({ title, subtitle, btnLabel, leagueSlug, background }: Props) => {
  return (
    <InstantSearch searchClient={searchClient} indexName={INDEX_NAMES.ORGANISATION}>
      <Configure
        hitsPerPage={10}
        filters={`${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${leagueSlug}`}
      />
      <CustomOrganisationsHits
        title={title}
        subtitle={subtitle}
        btnLabel={btnLabel}
        // onButtonClick={onButtonClick}
        background={background}
        leagueSlug={leagueSlug}
      />
    </InstantSearch>
  );
};

export default SectionOrgaSlider;

type HitsProps = {
  hits: Array,
  title: String,
  subtitle: String,
  btnLabel: String,
  leagueSlug: String,
  background: Boolean,
};

const OrganisationsHits = ({
  hits,
  title,
  subtitle,
  btnLabel,
  leagueSlug,
  background,
}: HitsProps) => {
  const organisations = hits?.map(hit => ({
    id: hit.objectID,
    name: hit.a_name,
    banner_url: hit.a_banner,
    slug: hit.a_slug,
    holding_slug: hit.a_holding_slug,
    holding: {
      name: hit.a_holding_name,
      short_description: hit.a_short_description,
      cover_url: hit.a_avatar,
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  const settings = {
    arrows: !isMobile,
    centerMode: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    speed: 500,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <Grid className={background && classes.gradientBackground}>
      <Grid className={background ? classes.layoutWithPadding : classes.layout}>
        {(title || subtitle) && (
          <Grid className={classes.headingContainer}>
            <Typography variant="h2" align="center" gutterBottom>
              {title}
            </Typography>
            <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
              {subtitle}
            </Typography>
          </Grid>
        )}
        {leagueSlug && organisations && organisations.length > 0 ? (
          <Slider {...settings}>
            {organisations.map(orga => (
              <Paper key={orga.id} className={classes.paperOrga} elevation={0}>
                <CardOrganisation
                  organisation={{ ...orga, link: Routes.organisationHomePath(orga) }}
                  hideMembers
                />
              </Paper>
            ))}
          </Slider>
        ) : (
          <Slider {...settings}>
            <Paper className={classes.paperOrga} elevation={0}>
              <CardOrganisation organisation={fakeOrganisation.orga1} />
            </Paper>
            <Paper className={classes.paperOrga} elevation={0}>
              <CardOrganisation organisation={fakeOrganisation.orga2} />
            </Paper>
            <Paper className={classes.paperOrga} elevation={0}>
              <CardOrganisation organisation={fakeOrganisation.orga3} />
            </Paper>
            <Paper className={classes.paperOrga} elevation={0}>
              <CardOrganisation organisation={fakeOrganisation.orga4} />
            </Paper>
            <Paper className={classes.paperOrga} elevation={0}>
              <CardOrganisation organisation={fakeOrganisation.orga5} />
            </Paper>
            <Paper className={classes.paperOrga} elevation={0}>
              <CardOrganisation organisation={fakeOrganisation.orga6} />
            </Paper>
          </Slider>
        )}
        {btnLabel && (
          <Grid align="right">
            <Button onClick={() => history.push('/structures')}>{btnLabel}</Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const CustomOrganisationsHits = connectHits(OrganisationsHits);
