import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LayoutStyles from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  heroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,
    width: '100%',
    height: '90vh',
    alignItems: 'flex-end',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    padding: '3rem 2rem 3rem 2rem',
    [theme.breakpoints.only('xs')]: {
      padding: '5rem 2rem 5rem 2rem',
    },
  },
  heroSubTitle1: {
    paddingLeft: '0.3rem',
    textShadow: '0.5px 0.5px #3c3c3c73',
    fontWeight: 400,
  },
}));

type Props = {
  title1: String,
  title1Color1: String,
  title1Color2: String,
  title2: String,
  title2Color1: String,
  title2Color2: String,
  subTitle: String,
  subTitleColor: String,
  variantSubTitle: String,
  componentSubTitle: String,
  backgroundImage: String,
};

function SectionHeroTitleDown({
  title1,
  title1Color1,
  title1Color2,
  title2,
  title2Color1,
  title2Color2,
  subTitle,
  subTitleColor,
  variantSubTitle,
  componentSubTitle,
  backgroundImage,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid>
      <Grid
        container
        align="left"
        className={classes.heroLayout}
        style={
          backgroundImage && {
            backgroundImage: backgroundImage,
          }
        }
      >
        <Grid>
          <Typography variant="h1" align="left" gutterBottom>
            <div
              className={classes.heroTitle1}
              style={
                title1Color1 &&
                title1Color2 && {
                  background: `linear-gradient(to right, ${title1Color1} 0%, ${title1Color2} 50%)`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  WebkitBoxDecorationBreak: 'clone',
                }
              }
            >
              {t(title1)}
            </div>
            {title2 && (
              <div
                style={
                  title2Color1 &&
                  title2Color2 && {
                    background: `linear-gradient(to right, ${title2Color1} 0%, ${title2Color2} 50%)`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    WebkitBoxDecorationBreak: 'clone',
                  }
                }
              >
                {t(title2)}
              </div>
            )}
          </Typography>
          {subTitle && (
            <Typography
              variant={variantSubTitle || 'h6'}
              component={componentSubTitle || 'div'}
              align="left"
              style={
                subTitleColor && {
                  color: `${subTitleColor}`,
                }
              }
              className={classes.heroSubTitle1}
            >
              {t(subTitle)}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default SectionHeroTitleDown;
