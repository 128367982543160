import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '4rem 2rem',
    flexWrap: 'nowrap',
    [theme.breakpoints.only('xs')]: {
      margin: '1rem',
      flexDirection: 'column',
    },
  },
  title2: {
    fontWeight: 600,
  },
}));

type Props = {
  icon: Function,
  title: String,
  title2: String,
  subtitle: String,
};

const ChbSectionHeader = ({ icon, title, title2, subtitle }: Props) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.layout}>
      {icon}
      <Grid>
        <Typography variant="h2" gutterBottom={!title2}>
          {title}
        </Typography>
        {title2 && (
          <Typography variant="h3" gutterBottom className={classes.title2}>
            {title2}
          </Typography>
        )}
        {subtitle && <Typography variant="body1">{subtitle}</Typography>}
      </Grid>
    </Grid>
  );
};

export default ChbSectionHeader;
