import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LayoutStyles from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    padding: '2rem 0rem 2rem 0rem',
    justifyContent: 'space-evenly',
  },
  card: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    margin: theme.spacing(2),
    padding: theme.spacing(4),
    height: 500,
  },
  textColor: {
    color: '#fff',
  },
}));

type Props = {
  cards: Array,
};

function SectionBackgroundImageCard({ cards }: Props) {
  const classes = useStyles();

  return (
    <Grid container className={classes.layout}>
      {cards.map(card => (
        <Grid item xs={12} md={6} key={card.id}>
          <Grid
            className={classes.card}
            style={{
              backgroundImage: card.whiteTitle
                ? `linear-gradient(rgb(0 0 0 / 70%), rgb(255 255 255 / 0%)),  url(${card.img})`
                : `url(${card.img})`,
              backgroundColor: card.whiteTitle && '#000',
            }}
          >
            <Typography
              variant="h3"
              align="center"
              className={card.whiteTitle && classes.textColor}
              gutterBottom
            >
              {card.title}
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              align="center"
              className={card.whiteTitle && classes.textColor}
              gutterBottom
            >
              {card.subtitle}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
export default SectionBackgroundImageCard;
