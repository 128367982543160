const orga1 = {
  id: 1,
  name: 'Métropole Lille',
  banner_url:
    'https://wello-avatars.s3.eu-west-1.amazonaws.com/a2c171d1-9ec7-498f-bc4f-c821a61a8474',
  holding: {
    short_description: "Plateforme d'accompagnement et de répit pour les aidants familiaux",
    name: 'Maison des Aidants',
    cover_url:
      'https://wello-avatars.s3.eu-west-1.amazonaws.com/3d4d0145-a233-4bf9-8aa8-f3a46c3329d4',
  },
  helper_count: 0,
  link: '/la-scalaa/scalaa',
};

const orga2 = {
  id: 2,
  name: 'Douai',
  banner_url:
    'https://wello-avatars.s3.eu-west-1.amazonaws.com/0b1cbd6b-5d38-4458-add1-9bafd3840238',
  holding: {
    short_description:
      'La PFR est destinée aux personnes qui accompagnent un proche atteint de troubles neuro-dégénératifs et maladies',
    name: 'PFR du Douaisis',
    cover_url:
      'https://wello-avatars.s3.eu-west-1.amazonaws.com/38503c45-bb6e-4dcc-829a-ed03ab5c0a72',
  },
  helper_count: 0,
  link: '/pfr-du-douaisis/plateforme-d-accompagnement-et-de-repit-des-aidants-du-douaisis',
};

const orga3 = {
  id: 3,
  name: 'Loire Atlantique',
  banner_url:
    'https://wello-avatars.s3.eu-west-1.amazonaws.com/7266ce51-b424-45df-99fd-616eafabc51f',
  holding: {
    short_description: 'Spécialiste des services et du maintien à domicile en Loire atlantique',
    name: 'Aide à domicile pour tous',
    cover_url:
      'https://wello-avatars.s3.eu-west-1.amazonaws.com/54b8b331-4a8b-4c42-90e7-6a4be3084a68',
  },
  helper_count: 0,
  link: 'aide-a-domicile-pour-tous/adt-44',
};

const orga4 = {
  id: 4,
  name: 'Maubeuge',
  banner_url:
    'https://wello-avatars.s3.eu-west-1.amazonaws.com/2e9eda99-5d8a-40d8-9080-576414f654f9',
  holding: {
    short_description:
      'L’AMF-AD est une association à but non lucratif, intervenant sur la perte d’autonomie.',
    name: 'AMF-AD',
    cover_url:
      'https://wello-avatars.s3.eu-west-1.amazonaws.com/63240a31-ec9e-451a-8fcc-cb3700e3de41',
  },
  helper_count: 0,
  link: '/amf-ad/amf-ad',
};

const orga5 = {
  id: 5,
  name: 'Flandre Intérieure',
  banner_url:
    'https://wello-avatars.s3.eu-west-1.amazonaws.com/6c0cba67-4b39-472b-b6f0-69dcd9bd8f9b',
  holding: {
    short_description: 'Votre proche a besoin de vous, mais vous de quoi avez-vous besoin ?',
    name: 'Maison des aidants',
    cover_url:
      'https://wello-avatars.s3.eu-west-1.amazonaws.com/e48d5b9b-3a89-40a6-b5e5-2377e582bcda',
  },
  helper_count: 0,
  link: '/maison-des-aidants-flandre-interieure/armentieres',
};

const orga6 = {
  id: 6,
  name: 'Nantes',
  banner_url:
    'https://wello-avatars.s3.eu-west-1.amazonaws.com/e72cbb45-23bd-409f-b147-dedd1091605e',
  holding: {
    short_description: 'Se faire de nouveaux amis, découvrir et pratiquer des activités variées...',
    name: 'Seniors et alors!',
    cover_url:
      'https://wello-avatars.s3.eu-west-1.amazonaws.com/1345af34-d6df-41fd-bf79-c34a80354533',
  },
  helper_count: 0,
  link: '/seniors-et-alors/seniors-et-alors-nantes',
};

export default {
  orga1,
  orga2,
  orga3,
  orga4,
  orga5,
  orga6,
};
